import Vue from "vue";
import "./plugins/fontawesome";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faVk } from "@fortawesome/free-brands-svg-icons";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { faYandex } from "@fortawesome/free-brands-svg-icons";
library.add(faYoutubeSquare);
library.add(faVk);
library.add(faYandex);
/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#footer");
