<template>
  <v-app>
    <v-footer dark padless>
      <v-card
        width="100%"
        flat
        tile
        color="#707070"
        class="white--text text-center"
      >
        <v-card-text>
          <v-btn
            href="https://yandex.ru/maps/org/medtaym/1646489054/?ll=30.512946%2C56.349029&z=16"
            class="mx-4 white--text"
            icon
          >
            <font-awesome-icon icon="fa-brands fa-yandex" size="2x" />
          </v-btn>
          <v-btn
            href="https://www.youtube.com/@medtimeclinic5687"
            class="mx-4 white--text"
            icon
          >
            <font-awesome-icon icon="fa-brands fa-youtube-square" size="3x" />
          </v-btn>
          <v-btn href="https://vk.com/medtime" class="mx-4 white--text" icon>
            <font-awesome-icon icon="fa-brands fa-vk" size="3x" />
          </v-btn>
        </v-card-text>
        <v-row>
          <v-col>
            <v-card-text class="white--text pt-0">
              <h3>Медицинский центр "Медтайм"</h3>
              <p>
                г. Великие Луки, ул. Л.Чайкиной, 23
                <br />

                <span class="my-auto"
                  ><v-icon class="pa-auto">mdi-phone-classic</v-icon
                  ><strong> 8-800-302-06-93</strong></span
                >
                <br />
                <v-icon>mdi-email</v-icon> help@medluki.ru <br />
                с 8:00 до 20:00 без выходных.
              </p>
              <v-btn href="https://medluki.ru/contacts.html" color="red">
                Контактная информация
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>МедТайм</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
